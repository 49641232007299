import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "safie-header"
}
const _hoisted_2 = { class: "safie-main" }
const _hoisted_3 = { class: "safie-footer" }

import { watchEffect, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SafieDialog',
  props: {
    visible: { type: Boolean },
    showClose: { type: Boolean },
    title: {},
    handleClose: { type: Function }
  },
  setup(__props: any) {

const props = __props;
const dialogVisible = ref(false);
watchEffect(() => {
  dialogVisible.value = props.visible;
});

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: dialogVisible.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dialogVisible).value = $event)),
    "show-close": _ctx.showClose,
    title: "Tips",
    "before-close": _ctx.handleClose,
    class: "safie-dialog",
    "close-on-click-modal": false,
    "close-on-press-escape": false
  }, {
    header: _withCtx(() => [
      (!_ctx.$slots.header && _ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.title), 1))
        : _renderSlot(_ctx.$slots, "header", { key: 1 })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "footer")
      ])
    ]),
    _: 3
  }, 8, ["modelValue", "show-close", "before-close"]))
}
}

})