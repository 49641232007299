import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Component, computed, PropType, StyleValue } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SafieButton',
  props: {
    type: {},
    size: {},
    preset: {},
    width: {},
    loading: { type: Boolean },
    disabled: { type: Boolean },
    icon: {},
    loadingIcon: {}
  },
  setup(__props: any) {

const props = __props;
const buttonClass = computed(() => {
  let newClass = "";
  if (props.type) {
    newClass += props.type + " ";
  }
  if (props.size) {
    newClass += props.size;
  }
  if (props.preset) {
    newClass += props.preset;
  }
  return newClass.trim();
});
const buttonStyle = computed(() => {
  const newStyle: StyleValue = {};
  if (props.width) {
    if (props.width <= 100) {
      newStyle.width = props.width + "%";
    } else {
      newStyle.width = props.width + "px";
    }
  }
  return newStyle;
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, {
    class: _normalizeClass(["safie-default-button", buttonClass.value]),
    loading: _ctx.loading,
    "loading-icon": _ctx.loadingIcon,
    icon: _ctx.icon,
    disabled: _ctx.disabled,
    style: _normalizeStyle(buttonStyle.value)
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon"),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["loading", "loading-icon", "icon", "class", "disabled", "style"]))
}
}

})