<template>
  <el-container class="page" v-loading.fullscreen="loading">
    <el-header class="header" height="80px">
      <Header :is-show="isShow" />
    </el-header>
    <el-container
      class="container"
      :class="{ 'no-side-footer-layout': layoutType === 'noAsideNoFooter' }"
    >
      <el-main
        class="main"
        :class="{
          'no-side-footer-layout-main': layoutType === 'noAsideNoFooter',
        }"
      >
        <el-container class="main-container">
          <el-aside width="auto" class="aside" v-if="layoutType === 'all'">
            <Menu />
          </el-aside>
          <el-main class="right-main">
            <router-view />
          </el-main>
        </el-container>
      </el-main>
      <el-footer class="footer" v-if="layoutType !== 'noAsideNoFooter'">
        <Footer />
      </el-footer>
    </el-container>
  </el-container>
</template>
<script lang="ts" setup>
import Footer from "@/common/components/Footer.vue";
import Header from "@/common/components/Header.vue";
import Menu from "@/common/components/Menu.vue";
import { useStore } from "@/store";
import { computed, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const layoutType = computed(() => route.meta.layoutType as string);
const isShow = ref(false);
const store = useStore();
const loading = computed(() => store.state.loading);
watchEffect(() => {
  switch (layoutType.value) {
    case "all":
      isShow.value = true;
      break;
    case "noAside":
      isShow.value = true;
      break;
    case "noAsideNoFooter":
      isShow.value = false;
      break;
    default:
      break;
  }
  if (layoutType.value === "all") {
    isShow.value = true;
  }
});
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  overflow: hidden;
  .header {
    border-bottom: 1px solid var(--safie-white-100);
    padding: 0;
  }
  .container {
    overflow: auto;
    &.no-side-footer-layout {
      flex-direction: column;
    }
    .main {
      background: var(--safie-white-100);
      padding: 0;
      flex: 1 0;
      flex-basis: calc(var(--vh, 1vh) * 100 - 80px);
      overflow: unset;
      &.no-side-footer-layout-main {
        flex: 1;
      }
      .main-container {
        height: 100%;
        .aside {
          background: #fff;
        }
        .right-main {
          border-bottom: 1px solid var(--el-border-color);
          padding: 0;
          min-height: 100%;
          position: relative;
          overflow: unset;
        }
      }
    }
    .footer {
      background: var(--safie-white-100);
      --el-footer-padding: 0;
      --el-footer-height: auto;
    }
  }
}
@media screen and (max-width: 996px) {
  .page {
    .container {
      .main {
        .main-container {
          .right-main {
            overflow: auto;
          }
        }
      }
    }
  }
}
</style>
