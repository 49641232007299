/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { Apikey } from "./apikey";
// May contain unused imports in some cases
// @ts-ignore
import { Apis } from "./apis";
// May contain unused imports in some cases
// @ts-ignore
import { DeviceOption } from "./device-option";

/**
 *
 * @export
 * @interface ApplicationDetail
 */
export interface ApplicationDetail {
  /**
   * アプリケーションID
   * @type {number}
   * @memberof ApplicationDetail
   */
  application_id?: number;
  /**
   * アプリケーション名
   * @type {string}
   * @memberof ApplicationDetail
   */
  application_name?: string;
  /**
   * アプリケーション毎に設定可能なディベロッパー表示名。
   * @type {string}
   * @memberof ApplicationDetail
   */
  developer_description?: string | null;
  /**
   * アプリケーションコード
   * @type {string}
   * @memberof ApplicationDetail
   */
  application_code?: string;
  /**
   * アプリケーションに適用するプラン。`application_auth_type=oauth2`の場合は、`production`を指定しなければならない。 `trial`: 試験用アプリケーション、権限のある任意のデバイスに対して少量のAPIコールが可能。 `production`: 本番アプリケーション、権限のあるSafie APIオプションプランが付与されたデバイスに対してプランで定められた量のAPIコールが可能。
   * @type {string}
   * @memberof ApplicationDetail
   */
  application_plan?: ApplicationDetailApplicationPlanEnum;
  /**
   * アプリケーションが利用する認証種別 `apikey`: APIキーを利用するアプリケーション `oauth2`: OAuth2認可プロセスを通して取得したアクセストークンを利用するアプリケーション
   * @type {string}
   * @memberof ApplicationDetail
   */
  application_auth_type?: ApplicationDetailApplicationAuthTypeEnum;
  /**
   * アプリケーション公開状況。`application_auth_type=oauth2`の場合は、`true`を指定しなければならない。 `true`: 公開中 (自developerに属さないユーザーがアプリケーションコードを入力することでアプリケーション利用申請することができる。ただし、`application_auth_type=oauth2`のアプリケーションに対する利用申請はできない。) `false`: 非公開
   * @type {boolean}
   * @memberof ApplicationDetail
   */
  published?: boolean;
  /**
   * このアプリケーションを利用するために許諾する必要のある権限のリスト。 `application_auth_type=apikey`の場合のみ指定可能。このアプリケーションで発行されたAPIキーでは、 ここで記述された権限の範囲内でのAPIアクセスのみが許可される。値は、`view`, `timeline`, `share`, `unshare`, `change_permission`, `talkback`, `view_clips`, `download`, `create_clips`, `delete_clips`, `device_setting`, `event_detection`, `delete_media`, `view_contract`, `location`, `ptz` の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApplicationDetail
   */
  permissions?: Set<string> | null;
  /**
   *
   * @type {Apis}
   * @memberof ApplicationDetail
   */
  apis?: Apis;
  /**
   * デベロッパーがアプリケーション作成時に選択した利用APIのリスト。`application_auth_type=apikey`の場合のみ指定可能。 この情報は、アプリケーション詳細画面での表示に利用されるのみであり、APIキーでのアクセス制御には影響を与えない。 値は、`device_image`, `event_register`, `location`, `still_capture`, `device_list`, `media_list`, `thumbnail`, `standard_event_list`, `shares`, `live`, `media_files`, `movie_clips`, `events`, `quotas`, `snapshots`, `ai_people_count`, `aiapp_people_count`, `ptz`, `device_others`(deprecated) の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApplicationDetail
   */
  selected_apis?: Set<string> | null;
  /**
   *
   * @type {Array<Apikey>}
   * @memberof ApplicationDetail
   */
  apikeys?: Array<Apikey>;
  /**
   * 設定されているscopeが複数の場合、スペース区切り文字列となる。 このアプリケーションで発行されたクライアントでは、ここで記述されたscopeの範囲のAPIアクセスのみが許可される。
   * @type {string}
   * @memberof ApplicationDetail
   */
  oauth2_scope?: string | null;
  /**
   * このアプリケーションでAPIアクセスする際に利用されるデバイスオプションの種別 `dedicated`（専用オプション）、`general`（汎用オプション）の二種類の値のみ
   * @type {string}
   * @memberof ApplicationDetail
   */
  device_option_type?: ApplicationDetailDeviceOptionTypeEnum;
  /**
   * アプリケーション作成時に選択された専用オプションの一覧 `device_option_type=dedicated`以外の場合にはnullとなる。
   * @type {Array<DeviceOption>}
   * @memberof ApplicationDetail
   */
  dedicated_options?: Array<DeviceOption> | null;
  /**
   * デベロッパーがアプリケーション作成時に選択した利用SDKのリスト。 値は `javascript` の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApplicationDetail
   */
  sdks?: Set<string> | null;
  /**
   * デベロッパーがアプリケーション作成時に選択したSDKで利用するAPIのリスト。 値は `streaming`, `device_image`, `device_list`, `media_list`, `location`, `thumbnail`, `standard_event_list`, `still_capture`, `users`, `player_ptz`, `devices`(deprecated) の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApplicationDetail
   */
  selected_sdk_apis?: Set<string> | null;
}

export const ApplicationDetailApplicationPlanEnum = {
  Trial: "trial",
  Production: "production",
} as const;

export type ApplicationDetailApplicationPlanEnum =
  (typeof ApplicationDetailApplicationPlanEnum)[keyof typeof ApplicationDetailApplicationPlanEnum];
export const ApplicationDetailApplicationAuthTypeEnum = {
  Apikey: "apikey",
  Oauth2: "oauth2",
} as const;

export type ApplicationDetailApplicationAuthTypeEnum =
  (typeof ApplicationDetailApplicationAuthTypeEnum)[keyof typeof ApplicationDetailApplicationAuthTypeEnum];
export const ApplicationDetailDeviceOptionTypeEnum = {
  Dedicated: "dedicated",
  General: "general",
} as const;

export type ApplicationDetailDeviceOptionTypeEnum =
  (typeof ApplicationDetailDeviceOptionTypeEnum)[keyof typeof ApplicationDetailDeviceOptionTypeEnum];
