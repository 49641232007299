import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

import Footer from "@/common/components/Footer.vue";
import Header from "@/common/components/Header.vue";
import Menu from "@/common/components/Menu.vue";
import { useStore } from "@/store";
import { computed, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute();
const layoutType = computed(() => route.meta.layoutType as string);
const isShow = ref(false);
const store = useStore();
const loading = computed(() => store.state.loading);
watchEffect(() => {
  switch (layoutType.value) {
    case "all":
      isShow.value = true;
      break;
    case "noAside":
      isShow.value = true;
      break;
    case "noAsideNoFooter":
      isShow.value = false;
      break;
    default:
      break;
  }
  if (layoutType.value === "all") {
    isShow.value = true;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_container, { class: "page" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, {
        class: "header",
        height: "80px"
      }, {
        default: _withCtx(() => [
          _createVNode(Header, { "is-show": isShow.value }, null, 8, ["is-show"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_container, {
        class: _normalizeClass(["container", { 'no-side-footer-layout': layoutType.value === 'noAsideNoFooter' }])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_main, {
            class: _normalizeClass(["main", {
          'no-side-footer-layout-main': layoutType.value === 'noAsideNoFooter',
        }])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_container, { class: "main-container" }, {
                default: _withCtx(() => [
                  (layoutType.value === 'all')
                    ? (_openBlock(), _createBlock(_component_el_aside, {
                        key: 0,
                        width: "auto",
                        class: "aside"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(Menu)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_main, { class: "right-main" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_view)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]),
          (layoutType.value !== 'noAsideNoFooter')
            ? (_openBlock(), _createBlock(_component_el_footer, {
                key: 0,
                class: "footer"
              }, {
                default: _withCtx(() => [
                  _createVNode(Footer)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  })), [
    [
      _directive_loading,
      loading.value,
      void 0,
      { fullscreen: true }
    ]
  ])
}
}

})