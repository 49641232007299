<template>
  <div class="menu-container">
    <div class="pc-menu">
      <div class="title flex">
        <span class="Safieicon">&#xe633;</span>{{ menuData.title }}
      </div>
      <div
        v-for="(item, index) in menuData.menu"
        :key="index"
        class="menu-content"
      >
        <div
          class="menu-title"
          @click.left="handleRouterTo(item.href, item.category)"
          :class="{ active: router.currentRoute.value.path === item.href }"
        >
          <span class="Safieicon" :class="[item.icon]"></span>
          <a @click.left.prevent :href="item.href">{{ item.title }}</a>
        </div>
        <div v-for="(itemTitle, itemIndex) in item.list" :key="itemIndex">
          <div
            class="menu-sub-title"
            @click.left="handleRouterTo(itemTitle.href, itemTitle.category)"
            :class="{
              active: router.currentRoute.value.path === itemTitle.href,
            }"
          >
            <span>-</span>
            <a @click.left.prevent :href="itemTitle.href">
              {{ itemTitle.title }}
            </a>
          </div>
          <div
            class="menu-sub-title active menu-current-title"
            v-if="
              currentKey === itemTitle.category &&
              router.currentRoute.value.path !== itemTitle.href
            "
          >
            <span>-</span>
            <a @click.left.prevent :href="router.currentRoute.value.path">
              {{ router.currentRoute.value.meta.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { useI18n } from "vue-i18n";
const router = useRouter();
const { t } = useI18n();
const menuData = ref({
  title: t("layout.MENU_MANAGE_TITLE"),
  menu: [
    {
      title: t("layout.MENU_APPLICATIONS_TITLE"),
      list: [
        {
          title: t("layout.MENU_API_TITLE"),
          href: "/applications/apikey-list",
          category: "api",
        },
        {
          title: t("layout.MENU_OAUTH_TITLE"),
          href: "/applications/oauth-list",
          category: "oauth",
        },
      ],
      href: "/applications",
      icon: "icon_icon-application",
      category: "applications",
    },
    {
      title: t("layout.MENU_PROFILE_TITLE"),
      href: "/user-setting-edit",
      icon: "icon_icon-developer",
      category: "user-setting-edit",
    },
  ],
});
const currentKey = ref(router.currentRoute.value.meta.category);
const handleRouterTo = (href: string, category: string) => {
  currentKey.value = category;
  router.push({ path: href });
};
onBeforeRouteUpdate((to) => {
  currentKey.value = to.meta.category;
});
</script>
<style lang="scss" scoped>
.menu-container {
  width: 244px;
  .pc-menu {
    padding: 0 16px;
    .title {
      border-bottom: 1px solid var(--safie-white-050);
      height: 62px;
      line-height: 62px;
      color: var(--safie-active-text);
      font-weight: 500;
      font-size: 17px;
      margin-bottom: 16px;
      span {
        color: var(--safie-green-400);
        padding-right: 14px;
        font-size: 24px;
      }
    }
    .menu-content {
      &:last-child {
        margin-top: 8px;
      }
    }
    .menu-title {
      padding: 14px 12px;
      color: var(--safie-native-footer-color);
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      span {
        color: #c8ccca;
        padding-right: 10px;
      }
      a {
        text-decoration: none;
        color: inherit;
        &:active,
        &:hover,
        &:visited {
          color: inherit;
        }
      }
    }
    .menu-sub-title {
      font-size: 14px;
      padding: 14px 0;
      font-weight: 400;
      color: var(--safie-native-footer-color);
      line-height: 20px;
      border-radius: 4px;
      margin-top: 8px;
      cursor: pointer;
      span {
        padding: 0 12px;
      }
      a {
        text-decoration: none;
        color: inherit;
        &:active,
        &:hover,
        &:visited {
          color: inherit;
        }
      }
    }
    .active {
      font-weight: 700;
      color: var(--safie-green-400);
      background: var(--safie-green-050);
      border-radius: 4px;
      span {
        color: var(--safie-green-400);
      }
      a {
        text-decoration: none;
        color: inherit;
        &:active,
        &:hover,
        &:visited {
          color: inherit;
        }
      }
    }
    .menu-current-title {
      span {
        padding-left: 36px;
      }
    }
  }
}
@media screen and (max-width: 996px) {
  .menu-container {
    display: none;
  }
}
</style>
