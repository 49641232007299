<template>
  <div class="footer-box">
    <div class="footer-top-part">
      <div class="footer-top-content">
        <div class="top-part-item">
          <!-- ホーム -->
          <a
            @click.left.prevent="router.push({ path: '/' })"
            class="part-item-text"
            href="/"
          >
            {{ t("layout.FOOTER_HOME") }}
          </a>
        </div>
        <div class="top-part-item">
          <!-- Safie Developersの使い方 -->
          <a
            @click.left.prevent="router.push({ path: '/tutorial/developers' })"
            class="part-item-text"
            href="/tutorial/developers"
          >
            {{ t("layout.FOOTER_TUTORIAL_DEVELOPERS") }}
          </a>
          <!-- OAuth2.0認証 -->
          <a
            @click.left.prevent="router.push({ path: '/tutorial/oauth' })"
            class="part-item-text"
            href="/tutorial/oauth"
          >
            {{ t("layout.FOOTER_OAUTH_TITLE") }}
          </a>
          <!--APIキー認証 -->
          <a
            @click.left.prevent="router.push({ path: '/tutorial/api-key' })"
            class="part-item-text"
            href="/tutorial/api-key"
          >
            {{ t("layout.FOOTER_API_TITLE") }}
          </a>
        </div>
        <div class="top-part-item">
          <!-- API設計ガイドライン -->
          <a
            @click.left.prevent="router.push({ path: '/guidelines' })"
            class="part-item-text"
            href="/guidelines"
          >
            {{ t("layout.FOOTER_API_DESIGN") }}
          </a>
          <!-- APIリファレンス -->
          <a
            @click.left.prevent="router.push({ path: '/reference/api' })"
            class="part-item-text"
            href="/reference/api"
          >
            {{ t("layout.FOOTER_API_REFERENCE") }}
          </a>
          <!-- API機能制約 -->
          <a
            @click.left.prevent="router.push({ path: '/terms-of-api' })"
            class="part-item-text"
            href="/terms-of-api"
          >
            {{ t("layout.FOOTER_API_RESTRICTION") }}
          </a>
        </div>
        <div class="top-part-item">
          <!-- SDK設計ガイドライン -->
          <a
            @click.left.prevent="router.push({ path: '/guidelines/sdk' })"
            class="part-item-text"
            href="/guidelines/sdk"
          >
            {{ t("layout.FOOTER_SDK_DESIGN") }}
          </a>
          <!-- SDKリファレンス -->
          <a
            @click.left.prevent="router.push({ path: '/reference/sdk' })"
            class="part-item-text"
            href="/reference/sdk"
          >
            {{ t("layout.FOOTER_SDK_REFERENCE") }}
          </a>
          <!-- SDK機能制約 -->
          <a
            @click.left.prevent="router.push({ path: '/terms-of-api/sdk' })"
            class="part-item-text"
            href="/terms-of-api/sdk"
          >
            {{ t("layout.FOOTER_SDK_RESTRICTION") }}
          </a>
        </div>
        <div class="top-part-item">
          <!-- よくあるご質問（FAQ） -->
          <a
            @click.left.prevent="router.push({ path: '/faq' })"
            class="part-item-text"
            href="/faq"
          >
            {{ t("layout.FOOTER_FAQ") }}
          </a>
          <!-- お問い合わせ -->
          <a
            href="https://safie.jp/contact/api/"
            class="part-item-text"
            target="_blank"
          >
            {{ t("layout.FOOTER_CONSULT") }}
            <span class="Safieicon bottom-part-icon">&#xe612;</span>
          </a>
          <!-- 活用事例 -->
          <a
            @click.left.prevent="router.push({ path: '/casestudy' })"
            class="part-item-text"
            href="/casestudy"
          >
            {{ t("layout.FOOTER_CASE") }}
          </a>
          <!-- リリースノート -->
          <a
            @click.left.prevent="router.push({ path: '/releasenotes' })"
            class="part-item-text"
            href="/releasenotes"
          >
            {{ t("layout.FOOTER_RELEASE_NOTES") }}
          </a>
        </div>
      </div>
    </div>
    <div class="footer-middle-part">
      <div class="content-left">
        <img
          class="content-icon"
          src="../../assets/images/10th-safie-logo.svg"
        />
        <span class="content-context">
          <div>{{ t("layout.FOOTER_COMPANY") }}</div>
        </span>
      </div>
    </div>
    <div class="footer-bottom-part">
      <a
        class="bottom-part-item"
        href="https://safie.co.jp/company/profile/"
        target="_blank"
      >
        <span class="bottom-part-text">{{
          t("layout.FOOTER_OPERATING_PROFILE")
        }}</span>
        <span class="Safieicon bottom-part-icon">&#xe612;</span>
      </a>
      <a class="bottom-part-item" href="/terms-of-service" target="_blank">
        <span class="bottom-part-text">{{
          t("layout.FOOTER_API_PROFILE")
        }}</span>
        <span class="Safieicon bottom-part-icon">&#xe612;</span>
      </a>
      <a
        class="bottom-part-item"
        href="https://safie.co.jp/privacy/"
        target="_blank"
      >
        <span class="bottom-part-text">{{ t("signup.IS_AGREE_LINK2") }}</span>
        <span class="Safieicon bottom-part-icon">&#xe612;</span>
      </a>
      <a
        class="bottom-part-item"
        href="https://safie.link/cookie/"
        target="_blank"
      >
        <span class="bottom-part-text">{{
          t("layout.FOOTER_COOKIE_LINK")
        }}</span>
        <span class="Safieicon bottom-part-icon">&#xe612;</span>
      </a>
      <a
        class="bottom-part-item"
        href="https://safie.co.jp/security/"
        target="_blank"
      >
        <span class="bottom-part-text">
          {{ t("layout.FOOTER_GUIDE_LINK") }}
        </span>
        <span class="Safieicon bottom-part-icon">&#xe612;</span>
      </a>
    </div>
    <div class="footer-bottom-company">
      <span>©︎</span>
      <span>Safie inc.</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
}
.footer-box {
  height: 100%;
  background-color: var(--safie-white-000);
  .footer-top-part {
    background: var(--safie-green-400);
    padding: 12px 64px;
    display: flex;
    justify-content: center;
    .footer-top-content {
      flex: 0 1 960px;
      display: flex;
      justify-content: space-between;
    }
  }
  .part-item-text {
    display: block;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    color: var(--safie-white-000);
    text-decoration: none;
    & + .part-item-text {
      margin-top: 16px;
    }
  }
  .footer-middle-part {
    padding: 12px 40px;
    display: flex;
    justify-content: space-between;
    .content-left {
      display: flex;
      .content-icon {
        font-size: 32px;
        margin-right: 40px;
      }
      .content-context {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #636f6c;
        align-content: center;
        white-space: nowrap;
      }
    }
  }
  .footer-bottom-part {
    display: flex;
    padding: 12px 40px;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 16px;
    .bottom-part-item {
      cursor: pointer;
      flex: none;
      padding-right: 16px;
      border-right: 1px solid #eeeeee;
      &:last-child {
        padding-right: 0;
        border-right: none;
      }
      &:top-child {
        margin-left: 0;
      }
      .bottom-part-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        color: #9e9e9e;
      }
      .bottom-part-icon {
        font-size: 12px;
        color: #9e9e9e;
      }
    }
  }
  .footer-bottom-company {
    display: flex;
    justify-content: end;
    padding-top: 6px;
    padding-bottom: 12px;
    padding-right: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7e8885;
    span:top-child {
      margin-right: 4px;
    }
  }
}

@media screen and (max-width: 928px) {
  .footer-box {
    .footer-bottom-part {
      .bottom-part-item {
        &:last-child {
          padding-right: 17px;
        }
      }
    }
    .footer-bottom-company {
      padding-right: 57px;
    }
  }
}

@media screen and (max-width: 640px) {
  .footer-box {
    .footer-top-part {
      display: none;
    }
    .footer-middle-part {
      padding: 12px 16px;
    }
    .footer-bottom-part {
      padding: 12px 16px;
      display: grid;
    }
    .footer-bottom-company {
      padding-top: 6px;
      padding-bottom: 12px;
      padding-right: 33px;
    }
  }
}
</style>
