const application = {
  APPLICATION_NAME_LABEL: "アプリケーション名称",
  APPLICATION_NAME_ERROR_LABEL: "アプリケーション名",
  APPLICATION_PLAN_LABEL: "プラン",
  APPLICATION_PLAN_LABEL_TRIAL: "トライアル",
  APPLICATION_PLAN_LABEL_PRODUCTION: "プロダクト",
  PUBLISHED_LABEL: "公開状況",
  DEMO_VIDEO_API_KEY: "APIキーまたはアクセストークン",
  PUBLISHED_OFF: "非公開",
  PUBLISHED_ON: "公開中",
  SELECTED_SERVICE_LABEL: "利用サービス",
  DEVICE_OPTION_TYPE_LABEL: "オプション種別",
  DEVELOPER_DESCRIPTION_LABEL: "このアプリケーション用のディベロッパー名",
  DEVELOPER_DESCRIPTION_OFF:
    "設定しない（アカウント情報に登録されているディベロッパー名を表示）",
  DEVELOPER_DESCRIPTION_ON: "設定する",
  DEDICATED_OPTION_LABEL: "専用オプション",
  DEDICATED_DIALOG_TITLE: "専用オプション選択",
  DEDICATED_DIALOG_TOP_MESSAGE: "選択できるオプションは最大10個です",
  DEDICATED_DIALOG_SAVE_ERROR_MESSAGE:
    "選択したオプションの数は10個を超えています。",
  EMPTY_DEDICATED_OPTION_LABEL: "未選択",
  DEDICATED_OPTION_BTN_LABEL: "選択",
  DEDICATED_OPTION_TABLE_ID_LABEL: "ID",
  DEDICATED_OPTION_TABLE_NAME_LABEL: "オプション名",
  PERMISSIONS_DER: "以下の権限について、サービス利用者の許可が必要となります。",
  CAMERA_OPTIONS_LABEL: "必要となるデバイスオプション",
  CORPORATION_OPTIONS_LABEL: "必要となるコーポレートオプション",
  PERMISSIONS_IMAGE_LABEL: "画像取得",
  PERMISSIONS_DEVICE_LIST_LABEL: "デバイス一覧取得",
  PERMISSIONS_MEDIA_LIST_LABEL: "メディア一覧取得",
  PERMISSIONS_LOCATION_LABEL: "位置情報取得",
  PERMISSIONS_THUMBNAIL_LABEL: "サムネイル取得",
  PERMISSIONS_STANDARD_EVENT_LIST_LABEL: "標準イベント一覧取得",
  PERMISSIONS_CAPTURE_LABEL: "スチルキャプチャ",
  PERMISSIONS_OTHER_LABEL: "その他",
  PERMISSIONS_SHARES_LABEL: "Shares v2",
  PERMISSIONS_LIVE_LABEL: "Live v2",
  PERMISSIONS_MEDIA_FILES_LABEL: "Media Files v2",
  PERMISSIONS_MOVIE_CLIPS_LABEL: "Movie Clips v2",
  PERMISSIONS_QUOTAS_LABEL: "Quotas v2",
  PERMISSIONS_SNAPSHOT_LABEL: "Snapshots v2",
  PERMISSIONS_AI_PEOPLE_COUNT_LABEL: "AI People Count v2",
  PERMISSIONS_PTZ_LABEL: "PTZ",
  PERMISSIONS_AI_APP_PEOPLE_COUNT_LABEL: "AI-App People \n Count",
  PERMISSIONS_APPLICATION_LINKS_LABEL: "Application Links v2",
  PERMISSIONS_EVENT_REGISTER_LABEL: "Events v2",
  PERMISSIONS_ENTRANCE2_LABEL: "Entrance v2",
  PERMISSIONS_USERS_LABEL: "Users v2",
  SDK_JS_DEVICES_LABEL: "Devices",
  SDK_JS_DEVICE_IMAGE_LABEL: "画像取得",
  SDK_JS_DEVICE_LIST_LABEL: "デバイス一覧取得",
  SDK_JS_MEDIA_LIST_LABEL: "メディア一覧取得",
  SDK_JS_LOCATION_LABEL: "位置情報取得",
  SDK_JS_THUMBNAIL_LABEL: "サムネイル取得",
  SDK_JS_STANDARD_EVENT_LIST_LABEL: "標準イベント一覧取得",
  SDK_JS_STILL_CAPTURE_LABEL: "スチルキャプチャ",
  SDK_JS_PLAYER_LABEL: "Player",
  SDK_JS_PTZ_LABEL: "Player + PTZ",
  SDK_JS_USERS_LABEL: "Users",
  SERVICE_CAMERA_LABEL: "映像視聴・ライブ",
  SERVICE_VIDEO_LABEL: "映像視聴・録画",
  SERVICE_SHARE_LABEL: "シェアの追加・削除",
  SERVICE_USER_LABEL: "他ユーザの権限変更",
  SERVICE_TELEPHONE_LABEL: "トークバック/通話の利用",
  SERVICE_DOWNLOAD_LABEL: "データダウンロード",
  SERVICE_DATA_LABEL: "データ作成・削除",
  SERVICE_DEVICE_LABEL: "デバイス設定の変更",
  SERVICE_DETECT_LABEL: "イベント検知設定の変更",
  SERVICE_VIDEODATA_LABEL: "録画データの削除",
  SERVICE_CONTRACT_LABEL: "契約情報の閲覧",
  SERVICE_LOCATION_LABEL: "位置情報",
  SERVICE_MEDIA_LABEL: "メディアクリップ視聴",
  SERVICE_ENTRANCE2_LABEL: "Entrance2情報の閲覧・変更",
  SERVICE_PTZ_LABEL: "PTZの利用",
  DEVICE_IMAGE_LABEL: "静止画 API（{n}万枚）",
  EVENT_REGISTER_LABEL: "外部イベント登録 API",
  LOCATION_LABEL: "位置情報取得 API",
  STILL_CAPTURE_LABEL: "スチルキャプチャ API",
  BASIC_FUNCTION_API_LABEL: "基本機能 API",
  LIVE_STREAM_LABEL: "ストリーミング API",
  MEDIA_DOWNLOAD_LABEL: "メディアファイル API（{n}日）",
  MOVIECLIP_LABEL: "ムービークリップ（32日）API",
  SNAPSHOT_LABEL: "スナップショットDL（10万枚）API",
  MOVIECLIP_SNAPSHOT_LABEL:
    "ムービークリップ（32日）＆スナップショットDL（10万枚） API",
  STREAMING_SDK_LABEL: "ストリーミング SDK",
  STREAMING_SDK_N_USERS_LABEL: "ストリーミング SDK（{n}ユーザー）",
  STREAMING_SDK_N_APPLICATIONS_LABEL:
    "ストリーミング SDK（{n}アプリケーション）",
  BASIC_SDK_LABEL: "基本機能 SDK",
  PEOPLE_DETECTION_LABEL: "AI People Count API",
  AI_APP_PEOPLE_COUNT_LABEL: "AI-App 人数カウント API",
  PTZ_LABEL: "PTZ API",
  ENTRANCE2_LABEL: "Entrance API",
  API_LABEL: "対応API：",
  SDK_LABEL: "対応SDK：",
  BASIC_FUNCTION_AVAILABLE_LABEL:
    "上記のデバイスオプションを付与することで以下の基本機能もご利用可能です。",
  ANNOTATION_AI_PEOPLE_COUNT_LABEL:
    "※ご利用に当たっては「AI People Countオプション」が必要です。",
  ANNOTATION_AI_APP_PEOPLE_COUNT_LABEL:
    "※ご利用に当たっては「AI-App 人数カウント オプション」が必要です。",
  ANNOTATION_PTZ_LABEL: "※ご利用に当たっては「PTZ オプション」が必要です。",
  ITEM_ID_LABEL: "ID：",
  ADD: "この内容で新規作成",
  BACK_BTN: "アプリケーション一覧に戻る",
  ADD_TIP:
    "アプリケーションを新たに作成する場合は、以下の各項目を入力して「この内容で新規作成」を押してください",
  APIS_TIP: "ご利用になりたいAPIにチェックを入れてください。",
  SDKS_TIP: "ご利用になりたいSDKにチェックを入れてください。",
  FINISH_TITLE: "新規作成完了",
  FINISH_TIP: "新規登録が完了しました。",
  SDK_FINISH_TIP1:
    "SDK (JavaScript) をご利用の場合、アクセス元のIPアドレスを制限することを推奨します。",
  SDK_FINISH_TIP2:
    "アプリケーション設定画面の「アクセス許可」より設定してください。",
  BACK_APIS_BTN: "APIキー管理に遷移する",
  CONFIRM_DES: "入力内容を保存せず、アプリケーション一覧に戻りますか？",
  // api add
  APPLICATION_ADD_TITLE: "APIキー認証アプリケーション",
  APPLICATION_ADD_SUB_TITLE: "APIキー認証アプリケーション新規作成",
  PLAN_PLACEHOLDER: "選択してください",
  NO_OPTIONS_TEXT: "必要なオプションはありません",
  ALL_DEVICE_OPTIONS_TEXT: "必要なデバイスオプションをそれぞれご契約ください。",
  ALL_CORPORATION_OPTIONS_TEXT:
    "必要なコーポレートオプションをそれぞれご契約ください。",
  // api edit
  BASIC_INFO_LABEL: "基本情報",
  API_KEY_LABEL: "APIキー管理",
  APPLICATION_CODE_LABEL: "アプリケーションコード",
  SELECTED_API_SERVICE_LABEL: "利用API",
  SELECTED_SDK_SERVICE_LABEL: "利用SDK",
  SELECTED_SERVICE_ERROR: "利用APIもしくは利用SDKを選択してください",
  SELECTED_SDK_JAVASCRIPT_LABEL: "JavaScript",
  API_KEY_DEVICES_LABEL: "Devices v2",
  BASIC_INFO_DELETE_TEXT: "このアプリケーションを削除",
  BASIC_INFO_SAVE_DIALOG_TITLE: "変更内容を保存しますか?",
  BASIC_INFO_SAVE_DIALOG_CONTENT:
    "アプリケーションを利用しているユーザーへ影響が発生する可能性があります。ご確認の上、変更をおこなってください。",
  BASIC_INFO_DELETE_DIALOG_TITLE: "“{n}”を削除しますか?",
  BASIC_INFO_DELETE_DIALOG_CONTENT:
    "アプリケーションを削除すると、アプリケーションに紐づく以下の情報が削除されます。<br/>・APIキー<br/>・デバイスへのアクセス権限<br/>・イベント定義",
  BASIC_INFO_DELETE_DIALOG_CONTENT_TIP: "確認のため“{n}”を入力してください。",
  BASIC_INFO_DELETE_DIALOG_CHECKBOX_TEXT:
    "上記に同意し、このアプリケーションを完全に削除します。",
  API_KEY_DELETE_DIALOG_CHECKBOX_TEXT:
    "上記に同意し、このAPIキーを完全に削除します。",
  CLIENT_DELETE_DIALOG_CHECKBOX_TEXT:
    "上記に同意し、このClientを完全に削除します。",
  RETURN_TIP_TEXT: "入力内容を保存せず、アプリケーション一覧に戻りますか？",
  API_KEY_ADD_DIALOG_TITLE: "APIキー新規発行",
  API_KEY_ADD_DIALOG_TIP:
    "APIキーを新規発行します。APIキーは外部へ共有しないでください。",
  API_KEY_DELETE_DIALOG_CONTENT:
    "APIキーを削除すると、このAPIキーを利用してのカメラのアクセスはできなくなります。<br/>削除すると設定を復元することはできません。",
  API_KEY_NAME_ERROR_TIP: "1〜32桁の半角英数字を入力してください",
  API_KEY_NAME_LABEL: "APIキー名",
  API_KEY_VALUE_LABEL: "APIキー",
  API_KEY_OPERATION_LABEL: "",
  API_KEY_CREATE_BTN: "APIキー新規発行",
  TITLE: "APIキー認証アプリケーション",
  SUB_TITLE: "APIキー認証アプリケーション設定",
  // イベント定義
  EVENT_DEFINITION_LABEL: "イベント定義",
  EVENT_ADD_BUTTON_TEXT: "新規作成",
  EVENT_TABLE_NAME_LABEL: "名称",
  EVENT_TABLE_ID_LABEL: "ID",
  EVENT_TABLE_COLOR_LABEL: "フラグ表示色",
  EVENT_TABLE_STATUS_LABEL: "イベントフラグ表示",
  EVENT_EDIT_BUTTON: "設定",
  EVENT_ENABLED_TEXT: "表示する",
  EVENT_DISABLED_TEXT: "表示しない",
  EVENT_ADD_TITLE: "イベント新規作成",
  EVENT_ADD_CONFIRM: "新規作成",
  EVENT_EDIT_TITLE: "イベント設定",
  EVENT_BASIC_ERROR:
    "最大文字数は、全角の場合8文字・半角の場合16文字まで。空文字は不可。",
  EVENT_SPECIAL_ERROR_TIP: "以下の値は指定できない(一部に含むことは可能)。",
  EVENT_SPECIAL_ERROR:
    "接続,切断,スナップショット,POS,モーション,サウンド,滞留検知,人検知,立ち入り検知",
  EVENT_COLOR_ERROR_LABEL: "表示色",
  // data number limit tip content
  APP_API_KEY_TOTAL_LIMIT:
    "アプリケーション作成数が上限（合計{n}）に達しています",
  APP_API_KEY_TRIAL_LIMIT:
    "トライアルプランのアプリケーション作成数が上限（{n}）に達しています",
  APP_API_KEY_PRODUCTION_LIMIT:
    "プロダクトプランのアプリケーション作成数が上限（{n}）に達しています",
  APP_API_KEY_DEDICATED_OPTION_LIMIT:
    "専用オプションのアプリケーション作成数が上限（{n}）に達しています",
  GET_QUOTA_ERROR:
    "クオータ情報の取得に失敗しました。再度操作を行ってください。",
  APP_OAUTH_TOTAL_LIMIT: "アプリケーション作成数が上限（{n}）に達しています",
  API_KEY_TOTAL_LIMIT: "APIキー作成数が上限（2）に達しています",
  CLIENT_TOTAL_LIMIT: "Client作成数が上限（2）に達しています",
  REDIRECT_URI_TOTAL: "Redirect URI作成数が上限（30）に達しています",
  EVENT_DEFINITION_TOTAL: "イベント作成数が上限（5）に達しています",
  // アクセス許可
  ACCESS_CONTROL_LABEL: "アクセス許可",
  ACCESS_CONTROL_ADD_BUTTON_TEXT: "新規登録",
  ACCESS_CONTROL_TOTAL: "アクセス許可設定数が上限（5）に達しています",
  ACCESS_CONTROL_TABLE_EMPTY_TEXT1:
    "すべてのIPアドレスからアクセスできる状態です。",
  ACCESS_CONTROL_TABLE_EMPTY_TEXT2:
    "IPアドレスを登録すると、それ以外のIPアドレスからのアクセスを遮断することができます",
  ACCESS_CONTROL_TABLE_SOURCE_IP_LABEL: "ソースIPアドレス",
  ACCESS_CONTROL_TABLE_MEMO_LABEL: "メモ",
  ACCESS_CONTROL_TABLE_OPERATION_LABEL: "",
  ACCESS_CONTROL_EDIT_BUTTON_LABEL: "設定",
  ACCESS_CONTROL_ADD_TITLE: "アクセス許可設定 新規登録",
  ACCESS_CONTROL_EDIT_TITLE: "アクセス許可設定 編集",
  ACCESS_CONTROL_ADD_CONFIRM: "新規登録",
  ACCESS_CONTROL_SOURCE_IP_INPUT_TIP: "xxx.xxx.xxx.xxx/32",
  ACCESS_CONTROL_SOURCE_IP_DIALOG_TEXT1:
    "IPv4アドレスもしくはそのCIDR表記で指定してください。",
  ACCESS_CONTROL_SOURCE_IP_DIALOG_TEXT2:
    "CIDR表記で指定する場合はホスト部のビットをすべて0で指定してください。",
  ACCESS_CONTROL_SOURCE_IP_DIALOG_TEXT3: "例: 192.168.1.1",
  ACCESS_CONTROL_SOURCE_IP_DIALOG_TEXT4: "例: 192.168.1.0/24",
  ACCESS_CONTROL_SOURCE_IP_ERROR_TIP_FOR_ALREADY_EXISTS:
    "指定したIPアドレスは既に登録されています",
  ACCESS_CONTROL_SOURCE_IP_ERROR_TIP_FOR_INVALID:
    "指定したIPアドレスの形式が不正です",
  ACCESS_CONTROL_MEMO_DIALOG_EDIT: "メモ（最大128文字）",
  ACCESS_CONTROL_DELETE_TITLE: "アクセス許可設定を削除しますか？",
  ACCESS_CONTROL_DELETE_DIALOG_TEXT1:
    "このIPアドレスからアクセスできなくなります。",
  ACCESS_CONTROL_DELETE_DIALOG_TEXT2:
    "削除すると設定を復元することはできません。",
  ACCESS_CONTROL_DELETE_DIALOG_TEXT3:
    "他の登録済みのIPアドレスがない場合、すべてのIPアドレスからのアクセスが許可されます。",
};
export default application;
