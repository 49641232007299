<template>
  <el-dialog
    v-model="dialogVisible"
    :show-close="showClose"
    title="Tips"
    :before-close="handleClose"
    class="safie-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    data-testid="safie-dialog-el-dialog"
  >
    <template #header>
      <template v-if="!$slots.header && title">
        <div class="safie-header">
          {{ title }}
        </div>
      </template>
      <slot v-else name="header"></slot>
    </template>
    <template #default>
      <div class="safie-main">
        <slot></slot>
      </div>
    </template>
    <template #footer>
      <div class="safie-footer">
        <slot name="footer"></slot>
      </div>
    </template>
  </el-dialog>
</template>
<script lang="ts" setup>
import { watchEffect, defineProps, ref } from "vue";

const props = defineProps<{
  visible: boolean;
  showClose?: boolean;
  title?: string;
  handleClose?: () => void;
}>();
const dialogVisible = ref(false);
watchEffect(() => {
  dialogVisible.value = props.visible;
});
</script>
<style lang="scss" scoped>
.el-dialog {
  padding: 0px;
}
</style>
