import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "footer" }

import { useStore } from "@/store";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import SafieButton from "./SafieButton.vue";
import SafieDialog from "./SafieDialog.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorModal',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const visible = computed(() => store.state.errorModal.visible);
const message = computed(() => store.state.errorModal.message);
const closeDialog = () => {
  store.commit("closeErrorModal");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(SafieDialog, {
      "show-close": false,
      title: _unref(t)('global.ERROR_MODAL_TITLE'),
      visible: visible.value
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "main",
          innerHTML: message.value
        }, null, 8, _hoisted_1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(SafieButton, {
            type: "cancel",
            onClick: closeDialog
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)("global.CLOSE_BTN")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["title", "visible"])
  ]))
}
}

})