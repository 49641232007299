import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "menu-container" }
const _hoisted_2 = { class: "pc-menu" }
const _hoisted_3 = { class: "title flex" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 0,
  class: "menu-sub-title active menu-current-title"
}
const _hoisted_9 = ["href"]

import { ref } from "vue";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { useI18n } from "vue-i18n";

export default /*@__PURE__*/_defineComponent({
  __name: 'Menu',
  setup(__props) {

const router = useRouter();
const { t } = useI18n();
const menuData = ref({
  title: t("layout.MENU_MANAGE_TITLE"),
  menu: [
    {
      title: t("layout.MENU_APPLICATIONS_TITLE"),
      list: [
        {
          title: t("layout.MENU_API_TITLE"),
          href: "/applications/apikey-list",
          category: "api",
        },
        {
          title: t("layout.MENU_OAUTH_TITLE"),
          href: "/applications/oauth-list",
          category: "oauth",
        },
      ],
      href: "/applications",
      icon: "icon_icon-application",
      category: "applications",
    },
    {
      title: t("layout.MENU_PROFILE_TITLE"),
      href: "/user-setting-edit",
      icon: "icon_icon-developer",
      category: "user-setting-edit",
    },
  ],
});
const currentKey = ref(router.currentRoute.value.meta.category);
const handleRouterTo = (href: string, category: string) => {
  currentKey.value = category;
  router.push({ path: href });
};
onBeforeRouteUpdate((to) => {
  currentKey.value = to.meta.category;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "Safieicon" }, "", -1)),
        _createTextVNode(_toDisplayString(menuData.value.title), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuData.value.menu, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "menu-content"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["menu-title", { active: _unref(router).currentRoute.value.path === item.href }]),
            onClick: _withModifiers(($event: any) => (handleRouterTo(item.href, item.category)), ["left"])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["Safieicon", [item.icon]])
            }, null, 2),
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["left","prevent"])),
              href: item.href
            }, _toDisplayString(item.title), 9, _hoisted_5)
          ], 10, _hoisted_4),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (itemTitle, itemIndex) => {
            return (_openBlock(), _createElementBlock("div", { key: itemIndex }, [
              _createElementVNode("div", {
                class: _normalizeClass(["menu-sub-title", {
              active: _unref(router).currentRoute.value.path === itemTitle.href,
            }]),
                onClick: _withModifiers(($event: any) => (handleRouterTo(itemTitle.href, itemTitle.category)), ["left"])
              }, [
                _cache[4] || (_cache[4] = _createElementVNode("span", null, "-", -1)),
                _createElementVNode("a", {
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["left","prevent"])),
                  href: itemTitle.href
                }, _toDisplayString(itemTitle.title), 9, _hoisted_7)
              ], 10, _hoisted_6),
              (
              currentKey.value === itemTitle.category &&
              _unref(router).currentRoute.value.path !== itemTitle.href
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _cache[5] || (_cache[5] = _createElementVNode("span", null, "-", -1)),
                    _createElementVNode("a", {
                      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["left","prevent"])),
                      href: _unref(router).currentRoute.value.path
                    }, _toDisplayString(_unref(router).currentRoute.value.meta.name), 9, _hoisted_9)
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}
}

})