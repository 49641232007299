import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "@/store";
import ja from "element-plus/dist/locale/ja.mjs";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { getCookie } from "./utils";
import ErrorModal from "@/common/components/ErrorModal.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();
const store = useStore();
const { t } = useI18n();
store.commit("closeLoading");
watch(
  () => store.state.scrollTopStatus,
  () => {
    const container = document.querySelector(".container");
    if (container) {
      container.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    }
  },
);
watch(
  () => route.path,
  () => {
    const container = document.querySelector(".container");
    if (container) {
      container.scrollTo(0, 0);
    }
  },
);

const setLoadingStatus = () => {
  // remember user setting page status when refresh page
  if (route.path === "/user-setting" || route.path === "/user-setting-audit") {
    store.commit("setUserSettingUnload", route.path);
  }
};
window.onbeforeunload = () => {
  setLoadingStatus();
  store.commit("closeErrorModal");
};

window.onpagehide = () => {
  setLoadingStatus();
  store.commit("closeErrorModal");
};

const resize = () => {
  // define --vh for sp
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  store.commit("setBodyWidth", document.body.clientWidth);
};

window.addEventListener("resize", resize);

onMounted(() => {
  const sessionIDCookieName =
    process.env.VUE_APP_SESSION_ID_COOKIE_NAME ?? "session_id";
  resize();
  if (!getCookie(sessionIDCookieName)) {
    store.commit("setIsLogin", false);
  }
});
onUnmounted(() => {
  window.removeEventListener("resize", resize);
});
// locale
const locale = ref(ja);
locale.value.el = {
  ...ja.el,
  // pagination
  pagination: {
    ...ja.el.pagination,
    goto: t("elementPlus.PAGINATION_GOTO_TEXT"),
    pagesize: t("elementPlus.PAGINATION_PAGE_SIZE_TEXT"),
    total:
      t("elementPlus.PAGINATION_TOTAL_PRE_TEXT") +
      "{total}" +
      t("elementPlus.PAGINATION_TOTAL_SUF_TEXT"),
    pageClassifier: t("elementPlus.PAGINATION_PAGE_CLASSIFIER_TEXT"),
  },
  // table
  table: {
    ...ja.el.table,
    emptyText: t("elementPlus.TABLE_EMPTY_TEXT"),
  },
  colorpicker: {
    ...ja.el.colorpicker,
    confirm: t("elementPlus.COLOR_PICKER_CONFIRM"),
    clear: t("elementPlus.COLOR_PICKER_CLEAR"),
  },
  select: {
    ...ja.el.select,
    placeholder: "",
  },
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createBlock(_component_el_config_provider, { locale: locale.value }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      _createVNode(ErrorModal)
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})